<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4 grey--text text--darken-3">
        Meeting Management
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" to="/createMeeting"
          ><v-icon left> mdi-plus </v-icon>Create Meeting</v-btn
        >
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">      
      <v-tabs v-model="tab">
        <v-tab>Moderating</v-tab>
        <v-tab>Participanting</v-tab>
        <v-tab>Interpreting</v-tab>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="reloadMeetingList"
              class="ma-1"
              icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-reload </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <moderatorMeetingList></moderatorMeetingList>
        </v-tab-item>
        <v-tab-item>
          <participantMeetingList></participantMeetingList>
        </v-tab-item>
        <v-tab-item>
          <interpreterMeetingList></interpreterMeetingList>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import moderatorMeetingList from "@/components/meetings/moderatorMeetingList.vue";
import participantMeetingList from "@/components/meetings/participantMeetingList.vue";
import interpreterMeetingList from "@/components/meetings/interpreterMeetingList.vue";

import { mapGetters } from "vuex";
export default {
  name: "manageMeetings",
  //TODO: [NMD-3] Need to figure out how to handle meeting
  data() {
    return {
      tab: null,
      
    };
  },
  components: {
    moderatorMeetingList,
    participantMeetingList,
    interpreterMeetingList,
    
  },
  computed: {
    ...mapGetters(["userProfile"]),
  },
  methods: {
    reloadMeetingList() {
      this.$store.dispatch("fetchModeratorMeetingList", this.userProfile.id);
      this.$store.dispatch("fetchParticipantMeetingList", this.userProfile.id);
      this.$store.dispatch("fetchInterpreterMeetingList", this.userProfile.id);
    },
  },
};
</script>