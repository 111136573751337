<template>
  <div>
    <editMeeting
      :meetingObj="meetingObj"
      v-if="isEditMeeting"
      @close="isEditMeeting = !isEditMeeting"
    ></editMeeting>
    <v-data-table
      v-else
      :loading="loading"
      :headers="headers"
      :items="getModeratorMeetingList"
    >
      <template v-slot:item.meetingTitle="{ item }">
        {{ item.data().configuration.meetingTitle }}
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.data().configuration.description }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.data().configuration.startDate | formatDate }}
      </template>
      <template v-slot:item.startTime="{ item }">
        {{ item.data().configuration.startTime | handleMissingTime }}
      </template>
      <template v-slot:item.duration="{ item }">
        {{ getDuration(item) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="joinMeeting(item)">
              <v-icon small color="primary"> mdi-video </v-icon>
            </v-btn>
          </template>
          <span>Join</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="editMeet(item)" v-bind="attrs" v-on="on" icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="deleteMeetingConfirmation(item)" v-bind="attrs" v-on="on" icon>
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog.show" max-width="25%">
      <v-card class="">
        <v-card-title class="d-flex justify-center">Delete this meeting?</v-card-title>
        <v-card-text class="d-flex justify-center">
          {{deleteDialog.meetingName}}
        </v-card-text>
        <v-card-actions class="px-5 pb-5 d-flex justify-center">
          <v-btn @click="deleteDialog = {}" elevation="0" outlined color="primary">
            <v-icon left>mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn @click="deleteMeeting(deleteDialog.meetingObj)" elevation="0" color="error" >
            <v-icon left>mdi-trash-can-outline</v-icon>
            Delete</v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format, parseISO, intervalToDuration } from "date-fns";
import moment from "moment";
import editMeeting from "./editMeeting.vue";
export default {
  name: "moderatorMeetingList",
  data() {
    return {
      deleteDialog:{show: false, meetingObj: null, meetingName: ''},      
      isEditMeeting: false,
      meetingObj: {},
      loading: true,
      headers: [
        { text: "Title", align: "Start", value: "meetingTitle" },
        { text: "Description", value: "description" },
        { text: "Date", align: "start", value: "startDate" },
        { text: "Time", align: "start", value: "startTime" },
        { text: "Duration", align: "start", value: "duration" },
        { text: "Actions", align: "center", value: "actions" },
      ],
    };
  },
  components: {
    editMeeting,
  },
  computed: {
    ...mapGetters(["getModeratorMeetingList", "userId"]),
  },
  methods: {
    deleteMeetingConfirmation(meeting){
      console.log
      this.deleteDialog = {
        show: true,
        meetingObj: meeting,
        meetingName: meeting.data().configuration.meetingTitle
      }
    },
    deleteMeeting(obj) {
      console.log(obj.id)
      this.$store.dispatch("deleteMeeting", obj.id).then((response) => {
        this.deleteDialog = {}
        this.$store.dispatch(
          "showSnackBar",
          {
            text: response,
            color: "success",
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      });
    },
    editMeet(obj) {
      this.isEditMeeting = true;
      this.meetingObj = obj;
    },
    joinMeeting(meetingObj) {
      console.log(meetingObj.data().meetingCode);
      this.$router.push("/connect/" + meetingObj.data().meetingCode);
    },
    getDuration(item) {
      let startTime = item.data().configuration.startTime;
      if (startTime !== null && startTime) {
        let endTime = moment(item.data().configuration.endTime, "hh:mm A");
        startTime = moment(startTime, "hh:mm A");
        let duration = moment.duration(endTime.diff(startTime));
        let hours = duration.get("hours");
        let minutes = duration.get("minutes");
        let time = "";
        if (hours > 0) time += `${hours} hr `;
        if (minutes > 0) time += `${minutes} min`;
        return time;
      } else {
        return "All Day";
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchModeratorMeetingList", this.userId).then(
      () => {
        this.loading = false;
        console.log("found");
      },
      (error) => {
        this.loading = false;
        this.$store.dispatch("showSnackBar", {
          text: error,
          color: "error",
        });
      }
    );
  },
  filters: {
    formatDate(val) {
      if (!val || val === null) {
        return "-";
      }
      return format(parseISO(val), "eee, MMM d, yyyy");
    },
    handleMissingTime(val) {
      if (!val || val == null) {
        return "All Day";
      }
      return val;
    },
  },
};
</script>