var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEditMeeting)?_c('editMeeting',{attrs:{"meetingObj":_vm.meetingObj},on:{"close":function($event){_vm.isEditMeeting = !_vm.isEditMeeting}}}):_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.getModeratorMeetingList},scopedSlots:_vm._u([{key:"item.meetingTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.meetingTitle)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.description)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data().configuration.startDate))+" ")]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("handleMissingTime")(item.data().configuration.startTime))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDuration(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.joinMeeting(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-video ")])],1)]}}],null,true)},[_c('span',[_vm._v("Join")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editMeet(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMeetingConfirmation(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"25%"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c('v-card',{},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v("Delete this meeting?")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.deleteDialog.meetingName)+" ")]),_c('v-card-actions',{staticClass:"px-5 pb-5 d-flex justify-center"},[_c('v-btn',{attrs:{"elevation":"0","outlined":"","color":"primary"},on:{"click":function($event){_vm.deleteDialog = {}}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Cancel")],1),_c('v-btn',{attrs:{"elevation":"0","color":"error"},on:{"click":function($event){return _vm.deleteMeeting(_vm.deleteDialog.meetingObj)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Delete")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }