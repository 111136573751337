var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.getInterpreterMeetingList},scopedSlots:_vm._u([{key:"item.meetingTitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.meetingTitle)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.description)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.startDate)+" ")]}},{key:"item.startTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.startTime)+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.endDate)+" ")]}},{key:"item.endTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data().configuration.endTime)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.joinMeeting(item)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-video")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }