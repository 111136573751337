<template>
  <div>
    <v-data-table :loading="loading" :headers="headers" :items="getParticipantMeetingList">
      <template v-slot:item.meetingTitle="{ item }">
        {{ item.data().configuration.meetingTitle }}
      </template>
      <template v-slot:item.description="{ item }">
        {{ item.data().configuration.description }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.data().configuration.startDate }}
      </template>
      <template v-slot:item.startTime="{ item }">
        {{ item.data().configuration.startTime }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ item.data().configuration.endDate }}
      </template>
      <template v-slot:item.endTime="{ item }">
        {{ item.data().configuration.endTime }}
      </template>
      <template v-slot:item.actions="{ item }">        
        <v-btn icon @click="joinMeeting(item)">
          <v-icon small color="primary">mdi-video</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "adminMeetingList",
  data() {
    return {
      loading: true,
      headers: [
        { text: "Title", align: "Start", value: "meetingTitle" },
        { text: "Description", value: "description" },
        { text: "Start Date", value: "startDate" },
        { text: "Start Time", value: "startTime" },
        { text: "End Date", value: "endDate" },
        { text: "End Time", value: "endTime" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getParticipantMeetingList", "userId"]),
  },
  methods:{
    joinMeeting(meetingObj){
      console.log(meetingObj.data().meetingCode);
      this.$router.push('/connect/'+ meetingObj.data().meetingCode)
    }

  },
  mounted() {
    this.$store.dispatch("fetchParticipantMeetingList", this.userId).then(
      () => {
        this.loading = false;
        console.log("found");
        
      },
      (error) => {
          this.loading = false;
        this.$store.dispatch("showSnackBar", {
          text: error,
          color: "error",          
        });
      }
    );
  },
};
</script>